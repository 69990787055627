<template>
  <div v-if="account" class="p-grid">
    <div class="p-col-12 p-md-6">
      <form @submit.prevent="handleSubmit">
        <div class="p-fluid p-grid">
          <h2>{{ $t("account.contact.title") }}</h2>
          <div class="p-field p-col-12">
            <p>{{ $t("account.contact.text") }}</p>
          </div>
          <div class="p-field p-col-12">
            <label for="first-name">{{
              $t("account.contact.field_first_name")
            }}</label>
            <InputText
              v-model="account.contact.name"
              aria-label="first-name"
              aria-describedby="first-name-error"
              :class="{ 'p-invalid': !!errors['name'] }"
            />
            <small
              v-if="!!errors['name']"
              id="first-name-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("account.contact.field_first_name_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="surname">{{
              $t("account.contact.field_surname")
            }}</label>
            <InputText
              v-model="account.contact.surname"
              aria-label="surname"
              aria-describedby="surname-error"
              :class="{ 'p-invalid': !!errors['surname'] }"
            />
            <small
              v-if="!!errors['surname']"
              id="surname-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("account.contact.field_surname_error") }}</small
            >
          </div>
          <div class="p-field p-col-12">
            <label for="email">{{ $t("account.contact.field_email") }}</label>
            <InputText
              v-model="account.contact.email"
              aria-label="email"
              aria-describedby="email-error"
              :class="{ 'p-invalid': !!errors['email'] }"
            />
            <small
              v-if="!!errors['email']"
              id="email-error"
              class="p-error"
              role="alert"
              aria-live="polite"
              >{{ $t("account.contact.field_email_error") }}</small
            >
          </div>
        </div>
        <div class="p-d-flex p-jc-end">
          <Button type="submit">
            {{ $t("common.save") }}
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import Contact from "../../models/account/Contact";
import { assertEmail, assertEmpty } from "../../service/assert";
import { UPDATE_ACCOUNT_CONTACT } from "../../store/modules/account/account.actions.type";
import { GET_ACCOUNT } from "../../store/modules/account/account.getters.type";

export default {
  name: "ChangeContact",
  components: { InputText, Button },
  setup() {
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();
    const errors = ref([]);
    const account = computed(() => store.getters[GET_ACCOUNT]);

    async function handleSubmit() {
      if (validateInputs()) {
        const accountUpdate = account.value.changeContact(
          new Contact(
            account.value.contact.name,
            account.value.contact.surname,
            account.value.contact.email
          )
        );
        await store.dispatch(UPDATE_ACCOUNT_CONTACT, accountUpdate);
        toast.add({
          severity: "success",
          summary: t("common.success"),
          detail: t("account.contact.toast_success"),
          life: TOAST_LIFE,
        });
      }
    }

    function validateInputs() {
      errors.value = [];
      if (assertEmpty(account.value.contact.name)) {
        errors.value["name"] = true;
      }
      if (assertEmpty(account.value.contact.surname)) {
        errors.value["surname"] = true;
      }
      if (!assertEmail(account.value.contact.email)) {
        errors.value["email"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    return {
      account,
      errors,
      handleSubmit,
    };
  },
};
</script>
