<template>
  <div v-if="account" class="p-grid">
    <div class="p-col-12 p-md-6">
      <form @submit.prevent="handleSubmit">
        <div class="p-fluid p-grid">
          <h2>{{ $t("account.unsubscribe.title") }}</h2>
          <div class="p-field p-col-12">
            <p>{{ $t("account.unsubscribe.text1") }}</p>
            <p>{{ $t("account.unsubscribe.text2") }}</p>
          </div>
        </div>
        <div class="p-d-flex p-jc-end">
          <Button type="submit" class="p-button-danger">
            {{ $t("account.unsubscribe.submit_button") }}
          </Button>
        </div>
      </form>
    </div>
    <ConfirmDialog />
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import { UNREGISTER_ACCOUNT } from "../../store/modules/account/account.actions.type";
import { GET_ACCOUNT } from "../../store/modules/account/account.getters.type";

export default {
  name: "Unregister",
  components: { Button, ConfirmDialog },
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const confirm = useConfirm();
    const { t } = useI18n();
    const errors = ref([]);
    const account = computed(() => store.getters[GET_ACCOUNT]);

    function onOpenDeleteModal() {
      confirm.require({
        header: t("account.unsubscribe.modal_unregister.header"),
        message: t("account.unsubscribe.modal_unregister.message"),
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: async () => {
          await store.dispatch(UNREGISTER_ACCOUNT);
          await router.push({ name: "login" });
          toast.add({
            severity: "success",
            summary: t("common.success"),
            detail: t("account.unsubscribe.modal_unregister.toast_success"),
            life: TOAST_LIFE,
          });
        },
      });
    }

    const handleSubmit = () => onOpenDeleteModal();

    return {
      account,
      errors,
      handleSubmit,
    };
  },
};
</script>
