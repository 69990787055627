<template>
  <section>
    <change-contact />
  </section>
  <Divider type="dashed" />
  <section>
    <change-billing />
  </section>
  <Divider type="dashed" />
  <section>
    <unregister />
  </section>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import Divider from "primevue/divider";
import { useStore } from "vuex";

import ChangeBilling from "@/components/account/ChangeBilling";
import ChangeContact from "@/components/account/ChangeContact";
import { FETCHING_ACCOUNT } from "@/store/modules/account/account.actions.type";
import { GET_ACCOUNT } from "@/store/modules/account/account.getters.type";

import Unregister from "../../components/account/Unregister";

export default {
  name: "AccountSettings",
  components: {
    ChangeBilling,
    ChangeContact,
    Unregister,
    Divider,
  },
  setup() {
    const store = useStore();
    onMounted(async () => {
      if (store.getters[GET_ACCOUNT] === null) {
        await store.dispatch(FETCHING_ACCOUNT);
      }
    });
  },
};
</script>
