<template>
  <div v-if="account" class="p-grid">
    <div class="p-col-12 p-md-6">
      <form @submit.prevent="handleSubmit">
        <div class="p-fluid p-grid">
          <h2>{{ $t("account.billing.title") }}</h2>
          <div class="p-field p-col-12">
            <p>{{ $t("account.billing.text") }}</p>
          </div>
          <div class="p-field p-col-12">
            <label for="company-name">{{
              $t("account.billing.field_company")
            }}</label>
            <InputText
              v-model="account.name"
              aria-label="company-name"
              type="text"
              :class="{ 'p-invalid': !!errors['name'] }"
            />
            <small v-if="!!errors['name']" class="p-error" role="alert">{{
              $t("account.billing.field_company_error")
            }}</small>
          </div>
          <div class="p-field p-col-12">
            <label for="address-line1">{{
              $t("account.billing.field_line1")
            }}</label>
            <InputText
              v-model="account.address.line1"
              aria-label="address-line1"
              type="text"
            />
          </div>
          <div class="p-field p-col-12">
            <label for="address-line2">{{
              $t("account.billing.field_line2")
            }}</label>
            <InputText
              v-model="account.address.line2"
              aria-label="address-line2"
              type="text"
            />
          </div>
          <div class="p-field p-col-12 p-md-6">
            <label for="address-city">City</label>
            <InputText
              v-model="account.address.city"
              aria-label="address-city"
              type="text"
            />
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label for="address-country">{{
              $t("account.billing.field_country")
            }}</label>
            <InputText
              v-model="account.address.country"
              aria-label="address-country"
              type="text"
            />
          </div>
          <div class="p-field p-col-12 p-md-3">
            <label for="address-postcode">{{
              $t("account.billing.field_postcode")
            }}</label>
            <InputText
              v-model="account.address.postcode"
              aria-label="address-postcode"
              type="text"
            />
          </div>
        </div>
        <div class="p-d-flex p-jc-end">
          <Button type="submit">
            {{ $t("common.save") }}
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

import { TOAST_LIFE } from "../../config";
import Address from "../../models/account/Address";
import { assertEmpty } from "../../service/assert";
import { UPDATE_ACCOUNT_BILLING } from "../../store/modules/account/account.actions.type";
import { GET_ACCOUNT } from "../../store/modules/account/account.getters.type";

export default {
  name: "ChangeBilling",
  components: { InputText, Button },
  setup() {
    const store = useStore();
    const toast = useToast();
    const { t } = useI18n();
    const errors = ref([]);
    const account = computed(() => store.getters[GET_ACCOUNT]);

    async function handleSubmit() {
      if (validateInputs()) {
        const accountUpdate = account.value.changeBilling(
          account.value.name,
          new Address(
            account.value.address.line1,
            account.value.address.line2,
            account.value.address.city,
            account.value.address.postcode,
            account.value.address.country
          )
        );
        await store.dispatch(UPDATE_ACCOUNT_BILLING, accountUpdate);
        await toast.add({
          severity: "success",
          summary: t("common.success"),
          detail: t("account.billing.toast_success"),
          life: TOAST_LIFE,
        });
      }
    }

    function validateInputs() {
      errors.value = [];
      if (assertEmpty(account.value.name)) {
        errors.value["name"] = true;
      }
      return Object.keys(errors.value).length === 0;
    }

    return {
      account,
      errors,
      handleSubmit,
    };
  },
};
</script>
